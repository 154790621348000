import { graphql, useStaticQuery } from "gatsby";
import { WerkVerzeichnisQuery } from "../../graphql-types";
import useWerke from "./useWerke";
import { FluidObject } from "gatsby-image";
import { compareAsc, compareDesc } from "date-fns";

const query = graphql`
  query WerkVerzeichnis {
    allGoogleSpreadsheetWerkverzeichnis {
      nodes {
        titel
        internenummer
        datum
        datum2
        datum3
        datum4
        datum5
        datum6
        datum7
        technik
        farbe
        standort
        thema
        original__PosthumMontiert
        breiteCm
        h_heCm
        interneBezeichnung
      }
    }
  }
`;

export type Werk = {
  internenummer: string;
  format: "quer" | "hoch";
  titel: "string";
  datum: Date;
  displayDate: string;
  datum2?: Date;
  datum3?: Date;
  datum4?: Date;
  datum5?: Date;
  datum6?: Date;
  datum7?: Date;
  technik?: string[];
  farbe?: string;
  standort?: string;
  thema: string[];
  original?: string;
  breite?: string;
  hoehe?: string;
  interneBezeichnung?: string;
  fluid: FluidObject;
};

const parseDate = (dateString: string): Date | undefined => {
  // date can be in different format, first we check if it's a number or a string. If the String ends with 00-00 we cut off that part to make it parseable
  if (!dateString) {
    return undefined;
  }

  let parsedDateString: number | string = "";
  // avoids the parsing of strings like (1995-00-00)
  if (!dateString.includes("-") && Number.parseInt(dateString)) {
    parsedDateString = Number.parseInt(dateString);
  } else if (dateString.endsWith("-00-00")) {
    parsedDateString = dateString.replace("-00-00", "").toString();
  } else if (dateString.endsWith("00-00")) {
    parsedDateString = dateString.replace("00-00", "").toString();
  } else if (dateString.match(/[0-9][0-9][0-9][0-9]-[0-9][0-9]-00/g)) {
    parsedDateString = dateString.replace("-00", "-01").toString();
  } else {
    parsedDateString = dateString.toString();
  }
  const date = new Date(parsedDateString);
  // @ts-ignore
  if (dateString && date instanceof Date && isFinite(date)) {
    return date;
  }
  return undefined;
};

const calculateFormat = (fluid: FluidObject): "quer" | "hoch" => (fluid.aspectRatio > 1 ? "quer" : "hoch");

const useFilteredWerke = ({ ascending }: { ascending: boolean }): Werk[] => {
  const data = useStaticQuery<WerkVerzeichnisQuery>(query);
  const werke = useWerke();
  const filteredWerke = data.allGoogleSpreadsheetWerkverzeichnis.nodes
    .filter(n => n.internenummer in werke)
    .map(n => ({
      internenummer: n.internenummer,
      // @ts-ignore
      titel: n.titel,
      datum: parseDate(n.datum),
      displayDate:
        String(n.datum).length > 11
          ? parseDate(n.datum)
            ? parseDate(n.datum)!.toISOString().slice(0, 10)
            : "Ohne Datum"
          : n.datum,
      datum2: parseDate(n.datum2),
      datum3: parseDate(n.datum3),
      datum4: parseDate(n.datum4),
      datum5: parseDate(n.datum5),
      datum6: parseDate(n.datum6),
      datum7: parseDate(n.datum7),
      technik: n.technik?.split(",").map(n => n.trim()) || ["Schwarz/Weiss"],
      farbe: n.farbe || "Schwarz/Weiss",
      standort: n.standort || undefined,
      original: n.original__PosthumMontiert || undefined,
      breite: n.breiteCm || undefined,
      hoehe: n.h_heCm || undefined,
      //@ts-ignore
      fluid: werke[n.internenummer].fluid as FluidObject,
      //@ts-ignore
      format: calculateFormat(werke[n.internenummer].fluid as FluidObject),
      interneBezeichnung: n.interneBezeichnung || undefined,
      thema: n.thema?.split(",").map(n => n.trim()) || [],
    }));

  const sortedFilteredWerke = ascending
  //@ts-ignore
  ? filteredWerke.sort((a, b) => (a.datum != null ? a.datum : Infinity) - (b.datum != null ? b.datum : Infinity))
  //@ts-ignore
  : filteredWerke.sort((a, b) => (b.datum != null ? b.datum : -Infinity) - (a.datum != null ? a.datum : -Infinity));
  
  //@ts-ignore
  return sortedFilteredWerke;
};

export default useFilteredWerke;
