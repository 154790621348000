import { Werk } from "./useFilteredWerke";

function groupBy<T, K extends keyof T>(items: T[], key: K) {
  return items.reduce(
    (result, item) => ({
      ...result,
      // @ts-ignore
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );
}

function groupByThema(werke: Werk[]) {
  const result = {};
  for (let werk of werke) {
    for (let thema of werk.thema) {
      // @ts-ignore
      result[thema] = result[thema] ? [...result[thema], werk] : [werk];
    }
  }
  return result;
}

function groupByTechnik(werke: Werk[]) {
  const result = {};
  for (let werk of werke) {
    for (let technik of werk.technik ?? []) {
      // @ts-ignore
      result[technik] = result[technik] ? [...result[technik], werk] : [werk];
    }
  }
  return result;
}

function useFilterKeys(werke: Werk[]) {
  const groupedByThema = groupByThema(werke);
  const groupedByFarbe = groupBy(werke, "farbe");
  const groupedByTechnik = groupByTechnik(werke);
  return {
    thema: groupedByThema,
    farbe: groupedByFarbe,
    technik: groupedByTechnik,
  };
}

export default useFilterKeys;
