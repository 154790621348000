import React, { FC, memo, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import useFilteredWerke from "../hooks/useFilteredWerke";
import useFilterKeys from "../hooks/useFilterKeys";
import useLocalStorage from "../hooks/useLocalStorage";
import Layout from "../components/Layout";
// @ts-ignore
import { ScrollContext } from "../context/ScrollContext.js";
import SEO from "../components/Seo";
import { Link, useScrollRestoration } from "gatsby";
import Image from "gatsby-image";
import { Routes } from "../constants/routes";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid as Grid, areEqual, FixedSizeList as List } from "react-window";
import memoize from "memoize-one";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import FilterModal from "../components/FilterModal";
import { Dropdown, Dropup, ArrowUp, ArrowDown } from "../components/Icons";
import { useQueryParam, JsonParam, BooleanParam, withDefault } from "use-query-params";
import { RecordParam } from "../utils/RecordParam";

const GridContainer = styled.div`
  padding-left: var(--s24);
  @media screen and (max-width: 699px) {
    display: none;
  }
  top: 0;
  min-height: calc(100vh - 6rem - 6rem);
  background: var(--light-gray);
  .grid {
    * > {
      display: flex;
      justify-content: center;
    }
  }
`;

const ListContainer = styled.div`
  padding: var(--s4);
  @media screen and (min-width: 700px) {
    display: none;
  }
  top: 0;
  min-height: calc(100vh - 6rem - 6rem);
  background: var(--light-gray);
  .list {
    * > {
      display: flex;
      justify-content: center;
    }
  }
`;

const FilterBarContainer = styled.div`
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--s12);
  color: var(--lighter-gray);
  @media screen and (max-width: 700px) {
    padding: var(--s4);
  }
`;

const DateText = styled.p`
  color: var(--lighter-gray);
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.4rem;
  @media (max-width: 600px) {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  margin-top: var(--s2);
  text-align: center;
  p {
    text-decoration: none;
  }
`;

const createGridItemData = memoize(({ columnCount, cards }) => ({
  columnCount,
  cards,
}));

const createListItemData = memoize(({ cards }) => ({
  cards,
}));

// @ts-ignore
const GridCell = memo(({ columnIndex, rowIndex, style, data }) => {
  const { cards, columnCount } = data;
  const singleColumnIndex = columnIndex + rowIndex * columnCount;
  const card = cards[singleColumnIndex];
  const [ascending] = useQueryParam("sortDirection", BooleanParam);
  return (
    <div style={style}>
      {card && (
        <Link
        to={`/${card.internenummer}/?sortDirection=${Number(ascending)}`}
        key={card.internenummer}
          style={{
            width: "150px",
            display: "grid",
            overflowY: "hidden",
          }}
        >
          <Image alt="" fluid={card.fluid} />
          <DateText>{card.displayDate}</DateText>
        </Link>
      )}
    </div>
  );
}, areEqual);

// @ts-ignore
const ListCell = memo(({ index, style, data }) => {
  const { cards } = data;
  const card = cards[index];
  const [ascending] = useQueryParam("sortDirection", BooleanParam);

  return (
    <div style={style}>
      {card && (
        <Link
        to={`/${card.internenummer}/?sortDirection=${Number(ascending)}`}
        key={card.internenummer}
          style={{
            width: "calc(100vw - 2rem)",
            height: "600px",
            display: "grid",
            objectFit: "cover",
          }}
        >
          <Image
            imgStyle={{
              objectFit: "contain",
            }}
            alt=""
            fluid={card.fluid}
            style={{ height: "600px" }}
          />
          <DateText>{card.displayDate}</DateText>
        </Link>
      )}
    </div>
  );
}, areEqual);

// @ts-ignore
const Cards = ({ cards }) => {
  const context = useContext(ScrollContext) as any;
  if (!context) {
    return null;
  }
  return (
    <>
      <GridContainer>
        <AutoSizer defaultWidth={1920} defaultHeight={1080}>
          {({ width, height }) => {
            const cardWidth = 230;
            const cardHeight = 525;
            const columnCount = Math.floor(width / cardWidth);
            const rowCount = Math.ceil(cards.length / columnCount);
            const itemData = createGridItemData({ cards, columnCount });
            return (
              <Grid
                onScroll={e => context.handleScroll(e.scrollTop)}
                initialScrollTop={context.scrollPosition}
                className="grid"
                width={width}
                height={height}
                columnCount={columnCount}
                columnWidth={cardWidth}
                rowCount={rowCount}
                rowHeight={cardHeight}
                itemData={itemData}
                style={{ margin: "0 auto" }}
              >
                {GridCell}
              </Grid>
            );
          }}
        </AutoSizer>
      </GridContainer>
      <ListContainer>
        <AutoSizer defaultWidth={1920} defaultHeight={1080}>
          {({ width, height }) => {
            const itemData = createListItemData({ cards });
            return (
              <List
                onScroll={e => context.handleScroll(e.scrollOffset)}
                className="list"
                initialScrollOffset={context.scrollPosition}
                width={width}
                height={height}
                itemCount={cards.length}
                itemData={itemData}
                itemSize={650}
              >
                {ListCell}
              </List>
            );
          }}
        </AutoSizer>
      </ListContainer>
    </>
  );
};

const IconButton = styled.div`
  border: none;
  background: none;
  cursor: pointer;
  svg {
    fill: var(--lighter-grey);
    width: var(--s10);
    height: var(--s10);
  }
`;

const DatumContainer = styled.button`
  display: grid;
  background: none;
  border: none;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: var(--s4);
  outline: none;
  cursor: pointer;
  color: var(--lighter-grey);
  font-size: 1.6rem;
  font-weight: 300;
`;

const WerkverzeichnisPage: FC = () => {
  const [isAscending, setIsAscending] = useQueryParam("sortDirection", withDefault(BooleanParam, false));
  const filteredWerke = useFilteredWerke({ ascending: isAscending });
  const filteredKeys = useFilterKeys(filteredWerke);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [query, setQuery] = useLocalStorage(
    "filter",
    Object.keys(filteredKeys).reduce((acc, next) => {
      // @ts-ignore
      acc[next] = [];
      return acc;
    }, {})
  );

  const handleSelect = (selection: any) => {
    setQuery(selection);
  };

  useEffect(() => {
    if (!isAscending) {
      setIsAscending(false);
    }
  }, []);

  const filterWerke = () => {
    if (!query) {
      return filteredWerke;
    }
    const selection = query;

    return filteredWerke.filter(werk =>
      Object.keys(selection).every(key => {
        if (selection[key].length === 0) {
          return true;
        }
        // @ts-ignore
        if (!werk[key]) {
          return false;
        }
        // @ts-ignore
        if (Array.isArray(werk[key])) {
          // @ts-ignore
          return werk[key].some(k => selection[key].includes(k));
        }
        // @ts-ignore
        return selection[key].includes(werk[key]);
      })
    );
  };



  return (
    <Layout activePage={Routes.WERKVERZEICHNIS}>
      <SEO title="Werkverzeichnis" />
      <FilterBarContainer>
        <DatumContainer onClick={() => setIsAscending(!isAscending)}>
          <p>Datum</p>
          <IconButton>{isAscending ? <ArrowUp /> : <ArrowDown />}</IconButton>
        </DatumContainer>
        <div style={{ position: "relative", display: "flex", cursor: "pointer" }}>
          <IconButton
            style={{ textDecoration: isFilterModalOpen ? "underline" : "none" }}
            onClick={e => setIsFilterModalOpen(!isFilterModalOpen)}
          >
            Filter
          </IconButton>
          <AnimatePresence>
            {isFilterModalOpen && filteredWerke.length > 0 && (
              <FilterModal
              handleReset={() => setQuery(null)}
              initialSelected={query}
              handleSelect={handleSelect}
                handleClose={() => setIsFilterModalOpen(false)}
                werke={filteredWerke}
                buttonLabel={filterWerke().length}
              />
            )}
          </AnimatePresence>
          <IconButton
            onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
            style={{ marginLeft: "0.5rem", marginTop: "0.25rem" }}
          >
            {isFilterModalOpen ? (
              <Dropup style={{ width: "2rem", height: "2rem" }} />
            ) : (
              <Dropdown style={{ width: "2rem", height: "2rem" }} />
            )}
          </IconButton>
        </div>
      </FilterBarContainer>
      <Cards cards={filterWerke()} />
    </Layout>
  );
};

export default WerkverzeichnisPage;
