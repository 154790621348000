import { graphql, useStaticQuery } from "gatsby";
import { AllImagesQuery } from "../../graphql-types";

const ALL_IMAGES = graphql`
  query AllImages {
    images: allFile(filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }) {
      edges {
        node {
          dir
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 800, quality: 30) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const isWerkImage = (path: string) => path.endsWith("/werke");

function useWerke() {
  const data = useStaticQuery<AllImagesQuery>(ALL_IMAGES);
  return data!
    .images!.edges!.filter(img => isWerkImage(img.node.dir))
    .map(img => ({
      fluid: img.node.childImageSharp!.fluid,
      internenummer: img.node.relativePath.substring(0, 4),
      relativePath: img.node.relativePath,
    }))
    // filter out specific werke
    .filter(werk => werk.internenummer !== "1552")
    .reduce((acc, next) => {
      // @ts-ignore
      acc[next.internenummer] = next;
      return acc;
    }, {});
}

export default useWerke;
