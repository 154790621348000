// @ts-nocheck
import React, { FC, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { CloseIcon } from "./Icons";
import useFilterKeys from "../hooks/useFilterKeys";
import { Werk } from "../hooks/useFilteredWerke";

type Props = {
  werke: Werk[];
  handleClose: () => void;
  handleSelect: (selection: any) => void;
  handleReset: () => void;
  initialSelected: any;
  buttonLabel: number;
};

const Container = styled(motion.div)`
  padding: var(--s4) 0;
  background: white;
  color: var(--black);
  position: absolute;
  box-shadow: var(--shadow-xl);
  top: 2.5rem;
  right: 0;
  z-index: 998;
  min-width: 40rem;
  .content {
    height: 60vh;
    overflow-y: scroll;
    padding-left: var(--s6);
    padding-right: var(--s6);
    @media (max-height: 540px) {
      height: 40vh;
    }
  }
  @media (max-width: 700px) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: auto;
    min-width: 0;
    .content {
      height: 80%;
    }
  }

  .submit-button {
    margin-left: var(--s6);
    margin-right: var(--s6);
    margin-top: var(--s6);
    background: #e3e1de;
    display: block;
    width: calc(100% - 3rem);
    padding: var(--s4) 0;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 300;
    @media (max-width: 700px) {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }

  .btn {
    background: none;
    border: none;
    color: var(--lighter-grey);
    transition: all 0.25s;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: var(--black);
    }
  }
  .topbar {
    padding-left: var(--s6);
    padding-right: var(--s6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    > button {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 300;
      @media (max-width: 700px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }
  .closeIcon {
    svg {
      fill: var(--lighter-grey);
      width: var(--s10);
      height: var(--s10);
      transition: all 0.25s;
      &:hover {
        fill: var(--black);
      }
    }
  }
  .filterTitle h4 {
    font-weight: 400;
    margin-top: var(--s12);
    font-size: 1.6rem;
    line-height: 2.4rem;
    @media (max-width: 700px) {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }

  .filterItem {
    outline: none;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    border: none;
    color: var(--black);
    text-decoration: none;
    width: 100%;
    font-size: 1.6rem;
    line-height: 2.4rem;
    .count {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 300;

      @media (max-width: 700px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
    @media (max-width: 700px) {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
    margin-top: var(--s3);
    .titlecontainer {
      display: flex;
      align-items: center;
    }
    .select {
      width: var(--s6);
      height: var(--s6);
      border: 1px solid var(--black);
      border-radius: 50%;
    }
    .title {
      margin-left: var(--s4);
      font-size: 1.6rem;
      line-height: 2.4rem;
      @media (max-width: 700px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }
`;

const FilterModal: FC<Props> = ({ werke, handleClose, handleSelect, initialSelected, handleReset, buttonLabel }) => {
  const filteredKeys = useFilterKeys(werke);
  const [selectedKeys, setSelectedKeys] = useState(() => {
    if (initialSelected) {
      return initialSelected;
    }
    return getKeys();
  });

  function getKeys() {
    return Object.keys(filteredKeys).reduce((acc, next) => {
      acc[next] = [];
      return acc;
    }, {});
  }

  function handleClick(firstKey: string, secondKey: string) {
    let newSelectedKeys = {};
    if (selectedKeys[firstKey].includes(secondKey)) {
      newSelectedKeys = { ...selectedKeys, [firstKey]: selectedKeys[firstKey].filter(k => k !== secondKey) };
    } else {
      newSelectedKeys = { ...selectedKeys, [firstKey]: [...selectedKeys[firstKey], secondKey] };
    }
    setSelectedKeys(newSelectedKeys);
    handleSelect(newSelectedKeys);
  }

  function handleResetClick() {
    handleReset();
    setSelectedKeys(getKeys());
  }

  const preferredOrderTechnik = ["collage", "druckgrafik", "tempera", "zeichnung", "frottage", "mischtechnik"];

  return (
    <Container initial={{ opacity: 0, y: -15 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -15 }}>
      <div className="topbar">
        <button className="btn" onClick={handleResetClick}>
          Filter zurücksetzen
        </button>
        <button onClick={handleClose} className="btn closeIcon">
          <CloseIcon />
        </button>
      </div>
      <div className="content">
        {["technik", "farbe", "thema"].map(k => (
          <div key={k} className="filterTitle">
            <h4>{k === "thema" ? "Themen (nicht vollständig)" : k[0].toUpperCase() + k.substring(1)}</h4>
            {Object.keys(filteredKeys[k as keyof typeof filteredKeys])
              .slice()
              .sort((a2, b2) =>
                k === "technik"
                  ? preferredOrderTechnik.indexOf(a2.toLowerCase()) - preferredOrderTechnik.indexOf(b2.toLowerCase())
                  : a2.localeCompare(b2)
              )
              .map(title => (
                <button className="btn" onClick={() => handleClick(k, title)} className="filterItem" key={title}>
                  <div className="titlecontainer">
                    <div
                      style={{ background: selectedKeys[k].includes(title) ? "black" : "#fcfbf7" }}
                      className="select"
                    ></div>
                    <div className="title">{title}</div>
                  </div>
                  <div className="count">{filteredKeys[k as keyof typeof filteredKeys][title].length}</div>
                </button>
              ))}
          </div>
        ))}
      </div>
      <button onClick={handleClose} className="submit-button">
        {buttonLabel} Werke anzeigen
      </button>
    </Container>
  );
};

export default FilterModal;
